import { Task } from "@lit/task";
import { LitElement, css, html } from "lit";
import tailwind from "./tailwind.css";

function reduceSize(size, amount = 2) {
  const sizes = [
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "4xl",
    "5xl",
    "6xl",
    "7xl",
    "8xl",
    "9xl",
  ];
  const index = sizes.indexOf(size);
  if (index - amount <= 0) {
    return sizes[0];
  }
  return sizes[index - amount];
}

const classes =
  "text-xs text-sm text-base text-lg text-xl text-2xl text-3xl text-4xl text-5xl text-6xl text-7xl text-8xl text-9xl";

export class PrebuiltTable extends LitElement {
  static properties = {
    preview: { type: Boolean },
    pricingTableID: { type: String },

    products: { type: Array },

    _defaultTitle: { state: true },
    _defaultButtonText: { state: true },
  };

  constructor() {
    super();
    this._defaultTitle = "Product Example";
    this._defaultButtonText = "Buy now";

    this.products = [];
  }

  createHTMLTemplate(input) {
    return html`<div class="container">
        ${input.map(
          (product) =>
            html` <div class="card">
              <div class="card-body">
                <div>
                  <div class="title">
                    ${product.styling.title ||
                    product.stripe_product.name ||
                    this._defaultTitle}
                  </div>
                  <div class="subtitle">${product.styling.subtitle}</div>
                </div>

                ${(() => {
                  if (product.stripe_product.name) {
                    if (
                      product.stripe_coupons.length === 0 ||
                      product.couponsComplete
                    ) {
                      return html`<div class="price-container">
                        <span class="discounted-price">
                          ${product.stripe_price.currency_symbol}${product
                            .stripe_price.unit_amount / 100}
                        </span>
                      </div>`;
                    } else {
                      return html`<div class="tiers-container">
                          ${(() => {
                            if (product.stripe_coupons.length === 1) {
                              return html`<div class="tier current-tier">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  width="1.25em"
                                  height="1.25em"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="current-tier-arrow"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                                  />
                                </svg>
                                <div>
                                  <span class="current-tier-highlight">
                                    ${product.stripe_price
                                      .currency_symbol}${product
                                      .stripe_coupons[0].amount_off || 0}
                                    off
                                  </span>
                                  for first
                                  ${product.stripe_coupons[0].max_redemptions ||
                                  0}
                                  customers
                                  <span class="current-tier-count">
                                    (${product.stripe_coupons[0]
                                      .max_redemptions -
                                      product.stripe_coupons[0]
                                        .times_redeemed ||
                                    product.stripe_coupons[0].max_redemptions ||
                                    0}
                                    left)
                                  </span>
                                </div>
                              </div>`;
                            } else {
                              let tierHtml = html``;
                              for (const [
                                index,
                                coupon,
                              ] of product.stripe_coupons.entries()) {
                                if (index < product.activeCouponIndex) {
                                  tierHtml = html`${tierHtml}
                                    <div class="tier previous-tier">
                                      ${product.stripe_price
                                        .currency_symbol}${coupon.amount_off ||
                                      0}
                                      off for first
                                      ${coupon.max_redemptions || 0} customers
                                    </div>`;
                                } else if (
                                  index === product.activeCouponIndex
                                ) {
                                  tierHtml = html`${tierHtml}
                                    <div class="tier current-tier">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        width="1.25em"
                                        height="1.25em"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="current-tier-arrow"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                                        />
                                      </svg>
                                      <div>
                                        <span class="current-tier-highlight">
                                          ${product.stripe_price
                                            .currency_symbol}${coupon.amount_off ||
                                          0}
                                          off
                                        </span>
                                        for first ${coupon.max_redemptions || 0}
                                        customers
                                        <span class="current-tier-count">
                                          (${coupon.max_redemptions -
                                            coupon.times_redeemed ||
                                          coupon.max_redemptions ||
                                          0}
                                          left)
                                        </span>
                                      </div>
                                    </div>`;
                                } else if (index > product.activeCouponIndex) {
                                  tierHtml = html`${tierHtml}
                                    <div class="tier next-tier">
                                      <span class="next-tier-price">
                                        ${product.stripe_price
                                          .currency_symbol}${coupon.amount_off ||
                                        0}
                                      </span>
                                      off for first
                                      ${coupon.max_redemptions || 0} customers
                                    </div>`;
                                }
                              }
                              return tierHtml;
                            }
                          })()}
                        </div>

                        <div class="price-container">
                          <div class="original-price">
                            ${product.stripe_price.currency_symbol}${product
                              .stripe_price.unit_amount / 100}
                          </div>
                          <div class="discounted-price">
                            ${product.stripe_price.currency_symbol}${product
                              .stripe_price.unit_amount /
                              100 -
                            (product.stripe_coupons[product.activeCouponIndex]
                              ?.amount_off || 0)}
                          </div>
                        </div>`;
                    }
                  } else {
                    return html`<div class="tiers-container">
                        <div class="tier previous-tier">
                          $150 off for first 10 customers
                        </div>
                        <div class="tier current-tier">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            width="1.25em"
                            height="1.25em"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="current-tier-arrow"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                            />
                          </svg>
                          <div>
                            <span class="current-tier-highlight">
                              $100 off
                            </span>
                            for first 100 customers
                            <span class="current-tier-count">(10 left)</span>
                          </div>
                        </div>
                        <div class="tier next-tier">
                          <span class="next-tier-price">$50</span> off for first
                          1000 customers
                        </div>
                      </div>

                      <div class="price-container">
                        <div class="original-price">$199</div>
                        <div class="discounted-price">$99</div>
                      </div>`;
                  }
                })()}

                <div class="features">
                  ${(() => {
                    if (
                      (!product?.styling?.features ||
                        product.styling.features.length === 0) &&
                      !product.stripe_product.name
                    ) {
                      return ["Feature 1", "Feature 2", "Feature 3"].map(
                        (feature) =>
                          html`<div class="feature">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              aria-hidden="true"
                              role="img"
                              class="green-tick"
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353l4.493-6.74a.75.75 0 0 1 1.04-.207"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                            ${feature}
                          </div>`
                      );
                    } else {
                      return product.styling.features.map(
                        (feature) =>
                          html`<div class="feature">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              aria-hidden="true"
                              role="img"
                              class="green-tick"
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353l4.493-6.74a.75.75 0 0 1 1.04-.207"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                            ${feature}
                          </div>`
                      );
                    }
                  })()}
                </div>

                <div class="cta-container">
                  ${(() => {
                    if (product.styling?.hasCustomButtonURL) {
                      return html`<a
                        href=${product.styling.buttonURL}
                        class="cta"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="cta-icon"
                          width="1.3em"
                          height="1.3em"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        ${product.styling.buttonText || this._defaultButtonText}
                      </a>`;
                    } else if (product.stripe_payment_link.url) {
                      const promoCode =
                        product.stripe_promo_codes[product.activeCouponIndex]
                          ?.code;
                      const href = promoCode
                        ? `${product.stripe_payment_link.url}?prefilled_promo_code=${promoCode}`
                        : `${product.stripe_payment_link.url}`;

                      return html`<a href=${href} target="_blank" class="cta">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="cta-icon"
                          width="1.3em"
                          height="1.3em"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        ${product.styling.buttonText || this._defaultButtonText}
                      </a>`;
                    } else {
                      return html`<button class="cta">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="cta-icon"
                          width="1.3em"
                          height="1.3em"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        ${product.styling.buttonText || this._defaultButtonText}
                      </button>`;
                    }
                  })()}
                </div>
                <div class="secure">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="secure-icon"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M8 1a3.5 3.5 0 0 0-3.5 3.5V7A1.5 1.5 0 0 0 3 8.5v5A1.5 1.5 0 0 0 4.5 15h7a1.5 1.5 0 0 0 1.5-1.5v-5A1.5 1.5 0 0 0 11.5 7V4.5A3.5 3.5 0 0 0 8 1m2 6V4.5a2 2 0 1 0-4 0V7z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Payment secured with Stripe
                </div>
              </div>
            </div>`
        )}
      </div>
    </div>`;
  }

  static styles = css`
    .loading {
      height: 400px;
      width: 280px;
      background-color: #f3f4f6;
      border-radius: 0.375rem;

      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;

      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      @keyframes pulse {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
      }
    }
    .container {
      display: flex;
      gap: 2.5rem;
      width: 100%;

      @media (min-width: 1050px) {
        flex-direction: row;
        justify-content: center;
      }
      @media (max-width: 1050px) {
        flex-direction: column;
        align-items: center;
      }
    }
    .card {
      max-width: 350px;
      background-color: #ffffff;
      border: 1px solid #e5e7eb;
      border-radius: 0.75rem;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    }
    .card-body {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    .title {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;
    }
    .subtitle {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #9ca3af;
    }
    .price-container {
      display: flex;
      flex-direction: row;
      margin-top: 1.75rem;
    }
    .original-price {
      font-weight: 700;
      font-size: 1.25rem;
      text-decoration: line-through;
      margin-right: 0.25rem;
      margin-top: 0.25rem;
      color: rgb(148 163 184);
    }
    .discounted-price {
      font-weight: 700;
      font-size: 2.5rem;
    }
    .tiers-container {
      margin-top: 1.5rem;
    }
    .tier {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .previous-tier {
      color: #4b5563;
      text-decoration-line: line-through;
    }
    .current-tier {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .current-tier-arrow {
      margin-right: 0.5rem;
    }
    .current-tier-highlight {
      font-weight: 700;
      font-size: 1.25rem;
      color: #22c55e;
    }
    .current-tier-count {
      font-weight: 700;
      text-wrap: nowrap;
    }
    .features {
      margin-top: 1rem;
    }
    .feature {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.15rem;
    }
    .green-tick {
      color: #34d399;
      margin-right: 0.3rem;
    }
    .cta-container {
      margin-top: 1.75rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .cta {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      padding-left: 0.875rem;
      padding-right: 0.875rem;

      display: flex;
      column-gap: 0.625rem;
      align-items: center;
      border-radius: 0.375rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 500;
      color: #ffffff;
      background-color: #a854f7;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      border-style: none;
      cursor: pointer;
      text-decoration: none;
    }
    .secure {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.5rem;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #4b5563;
    }
    .secure-icon {
      color: #10b981;
      margin-right: 0.15rem;
    }
  `;

  _fetchPricingTable = new Task(this, {
    task: async ([pricingTableID], { signal }) => {
      if (this.preview) {
        return this.products;
      } else {
        const url =
          process.env.FOMOPRICING_DEVELOPMENT === "development"
            ? "http://localhost:4000"
            : "https://fomo-pricing-api.app.eoin.website";
        const response = await fetch(`${url}/public/${pricingTableID}`, {
          signal,
        });
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      }
    },
    args: () => [this.pricingTableID, this.products],
  });

  render() {
    return this._fetchPricingTable.render({
      pending: () =>
        html`<div class="container">
          <div class="card">
            <div class="loading"></div>
          </div>
          <div class="card">
            <div class="loading"></div>
          </div>
        </div>`,
      complete: (result) => this.createHTMLTemplate(result),
      error: (e) => html`<p>Error: ${e}</p>`,
    });
  }
}
customElements.define("fomopricing-table", PrebuiltTable);

export class ActiveDiscount extends LitElement {
  static properties = {
    preview: { type: Boolean },
    pricingTableID: { type: String },
    product: { type: Array },

    size: { type: String },
    discountColor: { type: String },
    hideDiscountsAvailable: {
      type: Boolean,
      attribute: "hideDiscountsAvailable",
    },
    hideDiscountsRemaining: {
      type: Boolean,
      attribute: "hideDiscountsRemaining",
    },
  };

  constructor() {
    super();
    this.product = [];
    this.size = "md";
    this.discountColor = "";
    this.hideDiscountsAvailable = false;
    this.hideDiscountsRemaining = false;
  }

  createHTMLTemplate(product) {
    return html`<div class="text-${this.size}">
      ${(() => {
        if (product.stripe_coupons.length > 0) {
          return html`${(() => {
            return html`<div
              class="current-tier ${product.couponsComplete &&
              "previous-tier"} "
            >
              <div>
                <span class="current-tier-highlight">
                  ${product.stripe_price.currency_symbol}${product
                    .stripe_coupons[product.activeCouponIndex].amount_off || 0}
                  off
                </span>

                ${(() => {
                  if (!this.hideDiscountsAvailable) {
                    return html`for first
                    ${product.stripe_coupons[product.activeCouponIndex]
                      .max_redemptions || 0}
                    customers`;
                  }
                })()}
                ${(() => {
                  if (!this.hideDiscountsRemaining) {
                    return html`<span class="current-tier-count">
                      (${product.stripe_coupons[product.activeCouponIndex]
                        .max_redemptions -
                        product.stripe_coupons[product.activeCouponIndex]
                          .times_redeemed ===
                      0
                        ? "0"
                        : false ||
                          product.stripe_coupons[product.activeCouponIndex]
                            .max_redemptions ||
                          0}
                      left)
                    </span>`;
                  }
                })()}
              </div>
            </div>`;
          })()}`;
        } else {
          return html`<div class="current-tier">
            <div>
              <span class="current-tier-highlight"> $100 off </span>
              for first 100 customers
              <span class="current-tier-count">(10 left)</span>
            </div>
          </div>`;
        }
      })()}
    </div>`;
  }

  static styles = [
    tailwind,
    css`
      .loading {
        height: 50px;
        width: 100%;
        background-color: #f3f4f6;
        border-radius: 0.375rem;

        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        @keyframes pulse {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
      }
      .previous-tier {
        color: #4b5563;
        text-decoration-line: line-through;
      }
      .current-tier {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .current-tier-highlight {
        font-weight: 700;
        color: var(--discount-color, #black);
      }
      .current-tier-count {
        font-weight: 700;
        text-wrap: nowrap;
      }
    `,
  ];

  _fetchPricingTable = new Task(this, {
    task: async ([pricingTableID], { signal }) => {
      if (this.preview) {
        return this.product;
      } else {
        const url =
          process.env.FOMOPRICING_DEVELOPMENT === "development"
            ? "http://localhost:4000"
            : "https://fomo-pricing-api.app.eoin.website";
        const response = await fetch(`${url}/public/${pricingTableID}`, {
          signal,
        });
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      }
    },
    args: () => [
      this.pricingTableID,
      this.product,
      this.size,
      this.discountColor,
      this.hideDiscountsAvailable,
      this.hideDiscountsRemaining,
    ],
  });

  render() {
    this.style.setProperty("--discount-color", this.discountColor);

    return this._fetchPricingTable.render({
      pending: () => html`<div class="loading"></div>`,
      complete: (result) => this.createHTMLTemplate(result[0]),
      error: (e) => html`<p>Error: ${e}</p>`,
    });
  }
}
customElements.define("fomopricing-active-discount", ActiveDiscount);

export class Discounts extends LitElement {
  static properties = {
    preview: { type: Boolean },
    pricingTableID: { type: String },
    product: { type: Object },

    size: { type: String },
    discountColor: { type: String },
    hideDiscountsAvailable: {
      type: Boolean,
      attribute: "hideDiscountsAvailable",
    },
    hideDiscountsRemaining: {
      type: Boolean,
      attribute: "hideDiscountsRemaining",
    },
  };

  constructor() {
    super();
    this.product = {};
    this.size = "xl";
    this.discountColor = "";
    this.hideDiscountsAvailable = false;
    this.hideDiscountsRemaining = false;
  }

  createHTMLTemplate(product) {
    return html`
      ${(() => {
        if (product.stripe_coupons.length > 0) {
          return html` ${(() => {
            if (product.stripe_coupons.length === 1) {
              return html`<div
                class="tier current-tier ${product.couponsComplete &&
                "previous-tier"} text-${this.size}"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="1.25em"
                  height="1.25em"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="current-tier-arrow"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
                <div>
                  <span class="current-tier-highlight">
                    ${product.stripe_price.currency_symbol}${product
                      .stripe_coupons[0].amount_off || 0}
                    off
                  </span>

                  ${(() => {
                    if (!this.hideDiscountsAvailable) {
                      return html`for first
                      ${product.stripe_coupons[0].max_redemptions || 0}
                      customers`;
                    }
                  })()}
                  ${(() => {
                    if (!this.hideDiscountsRemaining) {
                      return html`<span class="current-tier-count">
                        (${product.stripe_coupons[0].max_redemptions -
                          product.stripe_coupons[0].times_redeemed ===
                        0
                          ? "0"
                          : false ||
                            product.stripe_coupons[0].max_redemptions ||
                            0}
                        left)
                      </span>`;
                    }
                  })()}
                </div>
              </div>`;
            } else {
              let tierHtml = html``;
              for (const [index, coupon] of product.stripe_coupons.entries()) {
                if (index < product.activeCouponIndex) {
                  tierHtml = html`${tierHtml}
                    <div
                      class="tier previous-tier text-${reduceSize(this.size)}"
                    >
                      ${product.stripe_price
                        .currency_symbol}${coupon.amount_off || 0}
                      off
                      ${(() => {
                        if (!this.hideDiscountsAvailable) {
                          return html`for first ${coupon.max_redemptions || 0}
                          customers`;
                        }
                      })()}
                    </div>`;
                } else if (index === product.activeCouponIndex) {
                  tierHtml = html`${tierHtml}
                    <div class="tier current-tier text-${this.size}">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        width="1.25em"
                        height="1.25em"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="current-tier-arrow"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                        />
                      </svg>
                      <div>
                        <span class="current-tier-highlight">
                          ${product.stripe_price
                            .currency_symbol}${coupon.amount_off || 0}
                          off
                        </span>
                        ${(() => {
                          if (!this.hideDiscountsAvailable) {
                            return html`for first ${coupon.max_redemptions || 0}
                            customers`;
                          }
                        })()}
                        ${(() => {
                          if (!this.hideDiscountsRemaining) {
                            return html`<span class="current-tier-count">
                              (${coupon.max_redemptions -
                                coupon.times_redeemed ===
                              0
                                ? "0"
                                : false || coupon.max_redemptions || 0}
                              left)
                            </span>`;
                          }
                        })()}
                      </div>
                    </div>`;
                } else if (index > product.activeCouponIndex) {
                  tierHtml = html`${tierHtml}
                    <div class="tier next-tier text-${reduceSize(this.size)}">
                      <span class="next-tier-price">
                        ${product.stripe_price
                          .currency_symbol}${coupon.amount_off || 0}
                      </span>
                      off
                      ${(() => {
                        if (!this.hideDiscountsAvailable) {
                          return html`for first ${coupon.max_redemptions || 0}
                          customers`;
                        }
                      })()}
                      ${(() => {
                        if (!this.hideDiscountsRemaining) {
                          return html`<span>
                            (${coupon.max_redemptions -
                              coupon.times_redeemed ===
                            0
                              ? "0"
                              : false || coupon.max_redemptions || 0}
                            left)
                          </span>`;
                        }
                      })()}
                    </div>`;
                }
              }
              return tierHtml;
            }
          })()}`;
        } else {
          return html`<div
              class="tier previous-tier text-${reduceSize(this.size)}"
            >
              $150 off for first 10 customers
            </div>
            <div class="tier current-tier text-${this.size}">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                width="1.25em"
                height="1.25em"
                stroke-width="1.5"
                stroke="currentColor"
                class="current-tier-arrow"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                />
              </svg>
              <div>
                <span class="current-tier-highlight"> $100 off </span>
                for first 100 customers
                <span class="current-tier-count">(10 left)</span>
              </div>
            </div>
            <div class="tier next-tier text-${reduceSize(this.size)}">
              <span class="next-tier-price">$50</span> off for first 1000
              customers
            </div>`;
        }
      })()}
    `;
  }

  static styles = [
    tailwind,
    css`
      .loading {
        height: 70px;
        width: 100%;
        background-color: #f3f4f6;
        border-radius: 0.375rem;

        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        @keyframes pulse {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
      }
      .tier {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .previous-tier {
        color: #4b5563;
        text-decoration-line: line-through;
      }
      .current-tier {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .current-tier-arrow {
        margin-right: 0.5rem;
      }
      .current-tier-highlight {
        font-weight: 700;
        color: var(--discount-color, black);
      }
      .current-tier-count {
        font-weight: 700;
        text-wrap: nowrap;
      }
    `,
  ];

  _fetchPricingTable = new Task(this, {
    task: async ([pricingTableID], { signal }) => {
      if (this.preview) {
        return this.product;
      } else {
        const url =
          process.env.FOMOPRICING_DEVELOPMENT === "development"
            ? "http://localhost:4000"
            : "https://fomo-pricing-api.app.eoin.website";
        const response = await fetch(`${url}/public/${pricingTableID}`, {
          signal,
        });
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      }
    },
    args: () => [
      this.pricingTableID,
      this.product,
      this.size,
      this.discountColor,
      this.hideDiscountsAvailable,
      this.hideDiscountsRemaining,
    ],
  });

  render() {
    this.style.setProperty("--discount-color", this.discountColor);

    return this._fetchPricingTable.render({
      pending: () => html`<div class="loading"></div>`,
      complete: (result) => this.createHTMLTemplate(result[0]),
      error: (e) => html`<p>Error: ${e}</p>`,
    });
  }
}
customElements.define("fomopricing-discounts", Discounts);

export class CurrentPrice extends LitElement {
  static properties = {
    preview: { type: Boolean },
    pricingTableID: { type: String },

    product: { type: Object },
    size: { type: String },
  };

  constructor() {
    super();
    this.product = {};
    this.size = "4xl";
  }

  createHTMLTemplate(product) {
    return html`${(() => {
      if (product.stripe_product.name) {
        if (product.stripe_coupons.length === 0 || product.couponsComplete) {
          return html`<div class="price-container">
            <span class="discounted-price text-${this.size}">
              ${product.stripe_price.currency_symbol}${product.stripe_price
                .unit_amount / 100}
            </span>
          </div>`;
        } else {
          return html`<div class="price-container">
            <div class="original-price text-${reduceSize(this.size, 3)}">
              ${product.stripe_price.currency_symbol}${product.stripe_price
                .unit_amount / 100}
            </div>
            <div class="discounted-price text-${this.size}">
              ${product.stripe_price.currency_symbol}${product.stripe_price
                .unit_amount /
                100 -
              (product.stripe_coupons[product.activeCouponIndex]?.amount_off ||
                0)}
            </div>
          </div>`;
        }
      } else {
        return html`<div class="price-container">
          <div class="original-price text-${reduceSize(this.size, 3)}">
            $199
          </div>
          <div class="discounted-price text-${this.size}">$99</div>
        </div>`;
      }
    })()}`;
  }

  static styles = [
    tailwind,
    css`
      .loading {
        height: 60px;
        width: 100px;
        background-color: #f3f4f6;
        border-radius: 0.375rem;

        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        @keyframes pulse {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
      }
      .price-container {
        display: flex;
        flex-direction: row;
        align-items: start;
      }
      .original-price {
        font-weight: 700;
        text-decoration: line-through;
        margin-right: 0.25rem;
        color: rgb(148 163 184);
      }
      .discounted-price {
        font-weight: 700;
      }
    `,
  ];

  _fetchPricingTable = new Task(this, {
    task: async ([pricingTableID], { signal }) => {
      if (this.preview) {
        return this.product;
      } else {
        const url =
          process.env.FOMOPRICING_DEVELOPMENT === "development"
            ? "http://localhost:4000"
            : "https://fomo-pricing-api.app.eoin.website";
        const response = await fetch(`${url}/public/${pricingTableID}`, {
          signal,
        });
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      }
    },
    args: () => [this.pricingTableID, this.product, this.size],
  });

  render() {
    return this._fetchPricingTable.render({
      pending: () =>
        html`<div class="price-container">
          <div class="loading"></div>
        </div>`,
      complete: (result) => this.createHTMLTemplate(result[0]),
      error: (e) => html`<p>Error: ${e}</p>`,
    });
  }
}
customElements.define("fomopricing-current-price", CurrentPrice);

export class BuyButton extends LitElement {
  static properties = {
    preview: { type: Boolean },
    pricingTableID: { type: String },
    product: { type: Object },

    buttonText: { type: String },
    buttonColor: { type: String },
    textColor: { type: String },
    buttonURL: { type: String },

    _defaultButtonText: { state: true },
  };

  constructor() {
    super();
    this.product = {};
    this.buttonText = "";
    this.buttonColor = "";
    this.textColor = "";
    this.buttonURL = "";

    this._defaultButtonText = "Buy now";
  }

  createHTMLTemplate(product) {
    return html`<div class="cta-container">
      ${(() => {
        if (this.buttonURL) {
          return html`<a href=${this.buttonURL} class="cta">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="cta-icon"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8"
                clip-rule="evenodd"
              ></path>
            </svg>
            ${this.buttonText || this._defaultButtonText}
          </a>`;
        } else if (product.stripe_payment_link.url) {
          const promoCode =
            product.stripe_promo_codes[product.activeCouponIndex]?.code;
          const href = promoCode
            ? `${product.stripe_payment_link.url}?prefilled_promo_code=${promoCode}`
            : `${product.stripe_payment_link.url}`;

          return html`<a href=${href} target="_blank" class="cta">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="cta-icon"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8"
                clip-rule="evenodd"
              ></path>
            </svg>
            ${this.buttonText || this._defaultButtonText}
          </a>`;
        } else {
          return html`<button class="cta">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="cta-icon"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8"
                clip-rule="evenodd"
              ></path>
            </svg>
            ${this.buttonText || this._defaultButtonText}
          </button>`;
        }
      })()}
    </div>`;
  }

  static styles = [
    tailwind,
    css`
      .loading {
        height: 40px;
        width: 120px;
        background-color: #f3f4f6;
        border-radius: 0.375rem;

        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        @keyframes pulse {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
      }
      .cta-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .cta {
        color: var(--text-color, white);
        background-color: var(--button-color, #9ca3af);

        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        padding-left: 0.875rem;
        padding-right: 0.875rem;

        display: flex;
        column-gap: 0.625rem;
        align-items: center;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;

        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border-style: none;
        cursor: pointer;
        text-decoration: none;
      }
    `,
  ];

  _fetchPricingTable = new Task(this, {
    task: async ([pricingTableID], { signal }) => {
      if (this.preview) {
        return this.product;
      } else {
        const url =
          process.env.FOMOPRICING_DEVELOPMENT === "development"
            ? "http://localhost:4000"
            : "https://fomo-pricing-api.app.eoin.website";
        const response = await fetch(`${url}/public/${pricingTableID}`, {
          signal,
        });
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      }
    },
    args: () => [
      this.pricingTableID,
      this.product,
      this.buttonText,
      this.buttonColor,
      this.textColor,
      this.buttonURL,
    ],
  });

  render() {
    this.style.setProperty("--button-color", this.buttonColor);
    this.style.setProperty("--text-color", this.textColor);

    return this._fetchPricingTable.render({
      pending: () =>
        html`<div class="cta-container">
          <div class="loading"></div>
        </div>`,
      complete: (result) => this.createHTMLTemplate(result[0]),
      error: (e) => html`<p>Error: ${e}</p>`,
    });
  }
}
customElements.define("fomopricing-buy-button", BuyButton);
